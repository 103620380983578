import type React from 'react'

type DomainFloatingMessageProps = {
  type: 'local' | 'staging'
}

export const DomainFloatingMessage: React.FC<DomainFloatingMessageProps> = ({ type }) => {
  let message: string
  let styleClass: string

  switch (type) {
    case 'local':
      message = 'Local environment'
      styleClass = 'bg-notification-attention-1-2 text-black'
      break
    case 'staging':
      message = 'Staging environment'
      styleClass = 'bg-notification-true-1-2 text-white'
      break
    default:
      message = 'Another environment'
      styleClass = 'bg-gray-5 text-gray-3'
  }

  return (
    <div
      className={`animate-blink fixed bottom-2.5 right-2.5 z-[99999] px-4 py-2.5 rounded-md drop-shadow-lg pointer-events-none ${styleClass}`}
    >
      <span className='text-base font-medium'>{message}</span>
    </div>
  )
}
