import type React from 'react'
import { useLocation, useParams } from 'react-router-dom'
import './styles/style.css'
import { useAuth, setLatestAdminLogin, removeLatestAdminLogin } from '@IJGN/potential'
import { useNavigate } from '@remix-run/react'
import * as Sentry from '@sentry/remix'
import { useEffect, useState } from 'react'
import { useSharedData } from './context/SharedDataProvider'
import Header from './layout/header'
import Sidebar from './layout/sidebar'
import CenterLoading from './routes/components/Loading/Password'

// ログインページは除き（再レンダリングを防ぐ目的）、この関数ではアクセスの権限処理は行わず、それらは個々のページで行う。
// ヘッダーとサイドバーの表示を制御する。
const AuthContainer = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { survey: survey_code, company: company_code } = useParams<{
    survey: string
    company: string
  }>()
  const { user, authWaiting } = useAuth()
  const { isAdmin, isCompanyPerson, company, isSharedDataLoading } = useSharedData()
  const [mainNode, setMainNode] = useState<React.ReactNode>(<CenterLoading />)

  Sentry.setUser({
    id: user?.id,
  })
  Sentry.setContext('extra_info', {
    isAdmin: isAdmin,
  })
  const rootPath = location.pathname === '/'
  // ログイン（システム管理者・企業担当者）
  // ヘッダーとサイドバーを表示しない
  const pageLogin =
    location.pathname.startsWith('/login') ||
    location.pathname.startsWith('/admin/login') ||
    location.pathname.startsWith('/set_initial_password') ||
    location.pathname.startsWith('/consultant/set_initial_password') ||
    location.pathname.startsWith('/reset_password') ||
    location.pathname.startsWith('/admin/reset_password') ||
    location.pathname.startsWith('/forgot_password') ||
    location.pathname.startsWith('/admin/forgot_password') ||
    location.pathname.startsWith('/expired_token')

  const pageSurveyAnswer = location.pathname.startsWith('/q/')
  const pageSurveyReport = location.pathname.startsWith(`/companies/${company_code}/surveys/${survey_code}/report`)

  useEffect(() => {
    if (authWaiting || isSharedDataLoading) {
      setMainNode(<CenterLoading />)
      return
    }
    if (pageLogin && user) {
      if (isAdmin) {
        setLatestAdminLogin()
      } else {
        removeLatestAdminLogin()
      }
      const redirectTo = new URLSearchParams(location.search).get('redirect_to') || ''
      const path = decodeURIComponent(redirectTo)
      // 他のサイトに飛ばされないように
      const safePath = path.startsWith('/') ? path : null
      if (safePath) {
        navigate(safePath)
        return
      }
      if (isCompanyPerson && company) {
        navigate(`/companies/${company.code}/dashboard`)
        return
      }
      if (isAdmin) {
        navigate('/admin/dashboard')
        return
      }
    }
    setMainNode(
      <>
        {!(rootPath || pageLogin || pageSurveyAnswer || pageSurveyReport) && (
          <>
            <Header />
            <Sidebar />
          </>
        )}
        {children}
      </>,
    )
  }, [
    user,
    authWaiting,
    pageLogin,
    children,
    isAdmin,
    navigate,
    isCompanyPerson,
    pageSurveyAnswer,
    pageSurveyReport,
    company,
    isSharedDataLoading,
    rootPath,
    location.search,
  ])

  return <>{mainNode}</>
}
export default AuthContainer
